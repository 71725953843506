<template>
  <Modal
    v-model:visible="visible"
    centered
    @ok="handleOk"
    class="modal barndmodal"
    :confirmLoading="confirmLoading"
    :zIndex="1"
    width="800px"
  >
    <template #title>
      <DecorateModalTitle :modalTitle="title" />
    </template>
    <div class="outcell">
      <Form
        :model="formState"
        :rules="rules"
        ref="formRef"
        :label-col="{ style: { width: '80px' } }"
      >
        <Form-item
          :label="level === 1 ? '分类名称' : '数据名称'"
          name="brandName"
        >
          <Input
            v-model:value="formState.brandName"
            placeholder="请输入"
            disabled
          />
        </Form-item>
        <Form-item :label="level === 1 ? '分类编码' : '数据编码'" name="code">
          <Input v-model:value="formState.code" placeholder="请输入" disabled />
        </Form-item>
        <!-- <Form-item label="所属分类">
          <Input v-model:value="inputName" disabled />
        </Form-item> -->
        <div style="position: relative">
          <Form-item label="排序" name="sort">
            <Input
              class="sort"
              v-model:value="formState.sort"
              placeholder="请输入权重 若不输入默认为1"
            />
          </Form-item>
          <div
            style="
              position: absolute;
              top: 20px;
              transform: translateY(-50%);
              right: -20px;
              color: #d9d9d9;
              font-size: 14px;
            "
          >
            <Popover>
              <template #content>
                <span style="color: #8c8c8c">权重值越大，排序越靠前</span>
              </template>
              <QuestionCircleOutlined class="icon" title="" />
            </Popover>
          </div>
        </div>
        <Form-item :label="level === 1 ? '分类描述' : '数据描述'" name="memo">
          <textarea
            v-model="formState.memo"
            cols="70"
            rows="5"
            maxlength="120"
            placeholder=""
            style="width: 360px"
            class="textarea"
          ></textarea>
        </Form-item>
      </Form>
    </div>
  </Modal>
</template>
<script>
import { defineComponent } from "vue";
import {
  Modal,
  Form,
  Input,
  Cascader,
  Upload,
  message,
  Image,
  Popover,
} from "ant-design-vue";
import {
  PlusOutlined,
  CloseCircleFilled,
  LoadingOutlined,
  EyeFilled,
  EditFilled,
  PictureOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons-vue";
import { unitUpdate } from "@/api/terminalList.js";
import { unitRules } from "@/rules/rules";
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
const { Item } = Form;
export default defineComponent({
  components: {
    DecorateModalTitle,
    Modal,
    Form,
    FormItem: Item,
    Input,
    Cascader,
    Upload,
    PlusOutlined,
    Image,
    CloseCircleFilled,
    LoadingOutlined,
    EyeFilled,
    EditFilled,
    PictureOutlined,
    Popover,
    QuestionCircleOutlined,
  },
  props: {
    brandList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      level: 1,
      visible: false,
      title: "",
      formState: {
        brandName: "",
        memo: null,
        sort: "",
        code: "",
      },
      rules: unitRules,
      //数据的联级选择选项
      options: [],
      cityCurrent: 0,
      notUploaded: true,
      nowDate: "",
      fileUrl: null,
      changeBrandParCode: true,
      imgFlag: true,
      filelist: [],
      inputName: "",
      confirmLoading: false,
      firstImg: "",
      recode: {},
    };
  },
  methods: {
    showModal(recode) {
      this.recode = recode;
      this.level = recode.level;
      if (recode.level === 1) {
        this.title = "编辑分类";
      } else {
        this.title = "编辑数据";
      }
      recode.seq = String(recode.seq);
      this.formState.brandName = recode.name || "";
      this.formState.memo = recode.memo || "";
      this.formState.sort = recode.seq || "";
      this.formState.code = recode.code || "";
      this.visible = true;
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        this.confirmLoading = true;
        let key = this.recode.level === 1 ? "CODE" : "VALUE";
        let mdCode = this.recode.level === 1 ? null : this.recode.mdCode;
        unitUpdate({
          mdCode: mdCode,
          name: this.formState.brandName.trim(),
          memo: this.formState.memo,
          seq: this.formState.sort,
          code: this.formState.code.trim(),
          key: key,
          id: this.recode.id,
        })
          .then((res) => {
            if (res.data.success) {
              message.success("编辑数据成功");
              this.visible = false;
              this.confirmLoading = false;
              this.$emit("todoTeload");
            } else {
              this.confirmLoading = false;
            }
          })
          .catch(() => {
            this.confirmLoading = false;
          });
      });
    },
  },
  mounted() {},
});
</script>
<style lang="scss" scoped>
.modal {
  border-radius: 20px;
}
input {
  width: 70%;
}
.tips {
  color: #ccc;
  text-align: center;
  position: absolute;
  bottom: 12px;
  left: 140px;
}
.textarea {
  border-radius: 5px;
  border-color: #e1e1e1;
}
.uploadBtn {
  width: 120px;
  height: 120px;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.upload-text {
  color: #bfbfbf;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
  .ant-upload-text {
    margin-top: 10px;
  }
}

.status {
  position: absolute;
  top: -50px;
  left: -50px;
}

.imgBox {
  width: 120px;
  position: relative;
  .imgIcon {
    position: absolute;
    top: -11px;
    right: -11px;
    cursor: pointer;
    color: #008bcf;
    display: none;
    font-size: 16px;
  }
  .editBox {
    font-size: 12px;
    color: #ffffff;
    font-weight: 600;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    display: none;
    .span {
      cursor: pointer;
      color: #fff;
    }
    .span:nth-last-of-type(1) {
      margin-left: 12px;
    }
  }
}
.mask {
  width: 120px;
  height: 120px;
  opacity: 0.6;
  background: #000000;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
.imgBox:hover .imgIcon {
  display: block;
}
.imgBox:hover .mask {
  display: block;
}
.imgBox:hover .editBox {
  z-index: 10;
  display: block;
}
.rules {
  color: #ff4d4f;
  font-size: 12px;
  line-height: 1.5715;
}
</style>
<style lang="scss" >
.barndmodal .ant-upload:not(.notUpload .ant-upload) {
  width: 120px;
  height: 120px;
  background: #fafafa;
  border-radius: 2px;
}
.barndmodal .ant-select-selection-placeholder {
  height: 40px !important;
  line-height: 40px !important;
}
.barndmodal .ant-select-selector {
  height: 40px !important;
  line-height: 40px !important;
}
.barndmodal .ant-select-selection-item {
  height: 40px !important;
  line-height: 40px !important;
}
.barndmodal input {
  width: 360px;
  height: 40px;
  line-height: 40px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
}
</style>