

//地区 columns  地区的固定表格第一行
export const areaColumns = [
  // {
  //   title: "序号",
  //   slots: { customRender: "spName" },
  //   fixed: 'left',
  //   // width:'150'
  //   align: "left",
  // },
  {
    title: "业务编码",
    dataIndex: "code",
    slots: { customRender: "code" },
  },
  {
    title: "省",
    dataIndex: "province",
  },
  {
    title: "省编码",
    dataIndex: "provinceCode",
  },
  {
    title: "市",
    dataIndex: "city",
  },
  {
    title: "市编码",
    dataIndex: "cityCode",
  },
  {
    title: "区",
    dataIndex: "area",
  },
  {
    title: "区编码",
    dataIndex: "areaCode",
  },
  {
    title: "镇",
    dataIndex: "town",
  },
  {
    title: "镇编码",
    dataIndex: "townCode",
  },
  {
    title: "层级",
    dataIndex: "level",
  },
  {
    title: "备注",
    dataIndex: "memo",
  },
  {
    title: "状态",
    slots: { customRender: "handle" },
    align: "left",
    fixed: "right",
    width: "150px",
  }
]


//地区 columns  节假日的固定表格第一行
export const holidayColumns = [

  {
    title: "阳历日期",
    dataIndex: "date",
    slots: { customRender: "date" },
  },
  {
    title: "年",
    dataIndex: "year",
  },
  {
    title: "月",
    dataIndex: "month",
  },
  {
    title: "日期类型",
    dataIndex: "daycode",
    slots: { customRender: "daycode" },
  },
  {
    title: "星期",
    dataIndex: "cnweekday",
  },
  {
    title: "农历年",
    dataIndex: "lunaryear",
  },
  {
    title: "农历月",
    dataIndex: "lunarmonth",
  },
  {
    title: "农历日",
    dataIndex: "lunarday",
  },
  {
    title: "文字提示",
    dataIndex: "info",
  },
  {
    title: "节日日期",
    dataIndex: "holiday",
  },
  {
    title: "节假日名称",
    dataIndex: "name",
  },
  {
    title: "是否需要上班",
    dataIndex: "isnotwork",
    slots: { customRender: "isnotwork" },
  },
  {
    title: "节假日薪资倍数",
    dataIndex: "wage",
  },
  // {
  //   title: "状态",
  //   slots: { customRender: "handle" },
  //   align: "left",
  //   fixed: "right",
  //   width: "150px",
  // }
]



// 终端 终端列表的固定表格第一行
export const terminalListColumns = [
  {
    title: "#",
    dataIndex: "fmesCode",
    slots: { customRender: "serialNumber" },
    width: 70,
    fixed: 'left',
  },
  {
    title: "终端名称/编码",
    dataIndex: "terminalName",
    slots: { customRender: "terminalName" },
    fixed: 'left',
    width: 250,
  },

  {
    title: "通路大类",
    dataIndex: "kindCategory",
    width: 100,
  },
  {
    title: "通路",
    dataIndex: "kind",
    width: 100,
  },
  {
    title: "渠道",
    dataIndex: "channel",
    width: 100,
  },
  {
    title: "子渠道",
    dataIndex: "subChannel",
    width: 100,
  },
  {
    title: "详细地址",
    dataIndex: "address",
    slots: { customRender: "province" },
    width: 250,
  },
  {
    title: "规模类型",
    dataIndex: "scaleType",
    slots: { customRender: "scaleType" },
    width: 100,
  },
  {
    title: "认证方式",
    dataIndex: "taskStatus",
    slots: { customRender: "taskStatus" },
    width: 100,
  },
  {
    title: "状态",
    //?terminalStatus
    dataIndex: "terminalStatus",
    slots: { customRender: "handle" },
    align: "left",
    fixed: "right",
    width: 50,
  }
]

// 综合运营中心 人工认证的固定表格第一行
export const ManualAuthenticationColumns = [
  {
    title: "#",
    dataIndex: "id",
    slots: { customRender: "serialNumber" },
    width: 70,
    fixed: 'left',
  },
  {
    title: "数据类型",
    dataIndex: "dataType",
    slots: { customRender: "dataType" },
    fixed: 'left',
    width: 100,
  },
  {
    dataIndex: "tenantName",
    slots: { title: 'customTitle', customRender: "tenantName" },
    fixed: 'left',
    width: 150,
  },
  {
    title: "终端名称/编码",
    dataIndex: "terminalName",
    slots: { customRender: "terminalName" },
    width: 250,

  },

  {
    title: "通路大类",
    dataIndex: "kindCategry",
    slots: { customRender: "kindCategry" },
    width: 100,
  },
  {
    title: "通路",
    dataIndex: "kind",
    slots: { customRender: "kind" },
    width: 100,
  },
  {
    title: "渠道",
    dataIndex: "channel",
    slots: { customRender: "channel" },
    width: 100,
  },
  {
    title: "子渠道",
    dataIndex: "subChannel",
    slots: { customRender: "subChannel" },
    width: 100,
  },
  {
    title: "详细地址",
    dataIndex: "address",
    slots: { customRender: "address" },
    width: 250,
  },
  {
    title: "规模类型",
    dataIndex: "scaleType",
    slots: { customRender: "scaleType" },
    width: 100,
  },
  {
    title: "操作",
    dataIndex: "operation",
    slots: { customRender: "operation" },
    width: 100,
  },
  {
    title: "AI匹配结果",
    dataIndex: "terminalCO.matchResult",
    width: 100,
  },
  {
    title: "认证方式",
    dataIndex: "terminalCO.unifyType",
    slots: { customRender: "unifyType" },
    width: 120,
  },
  {
    title: "认证",
    slots: { customRender: "handle" },
    align: "left",
    fixed: "right",
    width: 180,
  }
]


//品牌管理  column
export const brandColumns = [

  {
    title: "品牌名称",
    dataIndex: "brandName",
    slots: { customRender: "brandName" },
    fixed: 'left',
    width: 240,
  },
  {
    title: "品牌logo",
    dataIndex: "imgLogo",
    slots: { customRender: "logo" },
    width: 180,
  },
  {
    title: "品牌介绍",
    dataIndex: "memo",
    width: 340,
  }
]


//单品 columns  单品的固定表格第一行
export const singleProductColumns = [
  {
    title: "#",
    dataIndex: "id",
    slots: { customRender: "serialNumber" },
    width: 70,
    align: 'center',
    fixed: 'left',
  },
  {
    title: "单品名称/编码",
    dataIndex: "spName",
    slots: { customRender: "spName" },
    fixed: 'left',
    width: 250,
  },
  {
    title: "单品简称",
    dataIndex: "spNickName",
    width: 150,
  },
  {
    title: "单品条形码",
    dataIndex: "spBarCode",
    slots: { customRender: "spBarCode" },
    width: 150,
  },
  {
    title: "条形码唯一性",
    // dataIndex: "only",
    slots: { customRender: "only" },
    width: 100,
  },
  {
    title: "单品图片",
    dataIndex: "spBarCode",
    slots: { customRender: "spImage" },
    width: 72,
  },
  {
    title: "启用状态",
    slots: { customRender: "status" },
    align: "center",
    width: 100,
  },
  {
    title: "品类",
    dataIndex: "categoryName",
    width: 100,
  },
  {
    title: "品牌",
    dataIndex: "brandName",
    width: 100,
  },
  {
    title: "单位",
    dataIndex: "stockUnitCode",
    width: 100,
  },
  {
    title: "零售价（元）",
    dataIndex: "salesPrice",
    slots: { customRender: "salesPrice" },
    width: 100,
  },
  {
    title: "包装尺寸",
    dataIndex: "packageSize",
    slots: { customRender: "packageSize" },
    width: 100,
  },
  {
    title: "保质期（天）",
    dataIndex: "shelfLife",
    width: 100,
  },
  {
    title: "数据校准状态",
    slots: { customRender: "handle" },
    align: "left",
    fixed: "right",
    width: 100,
  },
  // {
  //   title: "操作",
  //   slots: { customRender: "handle2" },
  //   align: "left",
  //   fixed: "right",
  //   width: 100,
  // }
]

//单品 columns  单品 ->校验单品
export const singleProductCheckColumns = [
  {
    title: "#",
    dataIndex: "id",
    slots: { customRender: "serialNumber" },
    width: 70,
    align: 'center',
    fixed: 'left',
  },
  {
    title: "单品名称",
    dataIndex: "spName",
    slots: { customRender: "spName" },
    fixed: 'left',
    width: 150,
  },
  {
    title: "单品简称",
    dataIndex: "spNickName",
    slots: { customRender: "spNickName" },
    width: 120,
  },
  {
    title: "单品条形码",
    dataIndex: "spBarCode",
    slots: { customRender: "spBarCode" },
    width: 150,
  },
  {
    title: "单品图片",
    dataIndex: "spBarCode",
    slots: { customRender: "spImage" },
    width: 120,
  },
  {
    dataIndex: "categoryName",
    slots: { title: 'categoryTitle', customRender: "categoryName" },
    width: 100,
  },
  {
    dataIndex: "brandName",
    slots: { title: 'brandTitle', customRender: "brandName" },
    width: 100,
  },
  {
    title: "单位",
    dataIndex: "stockUnitCode",
    slots: { customRender: "stockUnitCode" },
    width: 100,
  },
  {
    title: "零售价（元）",
    dataIndex: "salesPrice",
    slots: { customRender: "salesPrice" },
    width: 100,
  },
  {
    title: "包装尺寸",
    dataIndex: "packageSize",
    slots: { customRender: "packageSize" },
    width: 200,
  },
  {
    title: "保质期（天）",
    dataIndex: "shelfLife",
    slots: { customRender: "shelfLife" },
    width: 100,
  },
  {
    title: "操作",
    slots: { customRender: "handle" },
    align: "center",
    fixed: "right",
    width: 100,
  }
]

//单品 columns  单品 ->相似单品
export const singleProductSimilarColumns = [
  {
    title: "#",
    dataIndex: "id",
    slots: { customRender: "serialNumber" },
    width: 70,
    align: 'center',
    fixed: 'left',
  },
  {
    title: "单品名称",
    dataIndex: "spName",
    slots: { customRender: "spName" },
    fixed: 'left',
    width: 150,
  },
  {
    title: "单品简称",
    dataIndex: "spNickName",
    slots: { customRender: "spNickName" },
    width: 120,
  },
  {
    title: "单品条形码",
    dataIndex: "spBarCode",
    slots: { customRender: "spBarCode" },
    width: 150,
  },
  {
    title: "单品图片",
    dataIndex: "spBarCode",
    slots: { customRender: "spImage" },
    width: 120,
  },
  {
    title: "品类",
    dataIndex: "categoryName",
    slots: { customRender: "categoryName" },
    width: 100,
  },
  {
    title: "品牌",
    dataIndex: "brandName",
    slots: { customRender: "brandName" },
    width: 100,
  },
  {
    title: "单位",
    dataIndex: "stockUnitCode",
    slots: { customRender: "stockUnitCode" },
    width: 100,
  },
  {
    title: "零售价（元）",
    dataIndex: "salesPrice",
    slots: { customRender: "salesPrice" },
    width: 100,
  },
  {
    title: "包装尺寸",
    dataIndex: "packageSize",
    slots: { customRender: "packageSize" },
    width: 150,
  },
  {
    title: "保质期（天）",
    dataIndex: "shelfLife",
    slots: { customRender: "shelfLife" },
    width: 100,
  },
]




//基础数据  column
export const unitColumns = [
  {
    title: "名称",
    dataIndex: "name",
    slots: { customRender: "unitName" },
    fixed: 'left',
    width: 200,
  },
  {
    title: "编码",
    dataIndex: "code",
    width: 200,
  },
  {
    title: "排序",
    dataIndex: "seq",
    width: 100,
  },
  {
    title: "描述",
    dataIndex: "memo",
    width: 340,
  }
]