
import { defineComponent, createVNode } from "vue";
import {
  Col,
  Row,
  Input,
  Button,
  Cascader,
  Table,
  Pagination,
  Popover,
  message,
  Image,
  Switch,
} from "ant-design-vue";
import {
  UnorderedListOutlined,
  SettingOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  FileAddFilled,
  PoweroffOutlined,
  ReloadOutlined,
  ColumnHeightOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SyncOutlined,
  SearchOutlined,
  TagsFilled,
} from "@ant-design/icons-vue";
import { unitList, unitUpdate } from "@/api/terminalList.js";
import { unitColumns } from "@/api/fixedData.js";
import Modaladd from "@/components/unit/Modal.vue";
import EditModal from "@/components/unit/EditModal.vue";
import ChildModal from "@/components/unit/ChildModal.vue";
export default defineComponent({
  components: {
    Col,
    Row,
    Input,
    Button,
    Cascader,
    UnorderedListOutlined,
    SettingOutlined,
    CloudUploadOutlined,
    CloudDownloadOutlined,
    FileAddFilled,
    PoweroffOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    TagsFilled,
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    SyncOutlined,
    SearchOutlined,
    Table,
    Pagination,
    Popover,
    Modaladd,
    Image,
    ChildModal,
    EditModal,
    Switch,
  },
  data() {
    return {
      //table的数据
      data: [],
      //table的列名字
      columns: [],
      // 当前页数
      pageCurrent: 1,
      // 每页数据数量
      pageSize: 10,
      pageNum: 1,
      //总数据量
      total: 0,
      tableHeight: "400px",
      searchFlag: false,
      //table的布局
      scroll: { x: "100%" },
      //table的loading
      loading: true,
      //批量操作按钮加载
      batchLoading: false,
      //table的样式
      batchStyle: "middle",
      //搜索框的内容
      searchBrand: "",
      //是否展示table 每次table不渲染defaultExpandAllRows才生效
      showTable: false,
      //是否全部展开table数据
      defaultExpandAllRows: false,
      //默认展开行
      defaultExpandedRowKeys: [],
    };
  },
  methods: {
    //更换每页显示数量
    onShowSizeChange(current: any, pageSize: any) {
      this.pageNum = 1;
      this.pageCurrent = 1;
      this.pageSize = pageSize;
      this.getBrandList();
    },
    showModal(recode) {
      (this as any).$refs.showModal.showModal(recode);
    },
    showEditModal(recode) {
      (this as any).$refs.showEditModal.showModal(recode);
    },
    showChildModal(recode) {
      (this as any).$refs.showChildModal.showModal(recode);
    },
    //启用禁用数据 单个
    handleDeleteBrand(recode) {
      let status = recode.status === 0 ? 1 : 0;
      let key = recode.level === 1 ? "CODE" : "VALUE";
      unitUpdate({
        status: status,
        key: key,
        id: recode.id,
        mdCode: recode.mdCode,
      }).then(() => {
        (this as any).defaultExpandedRowKeys = [recode.mdCode];
        this.getBrandList();
      });
    },
    //获取数据列表
    getBrandList() {
      this.loading = true;
      this.showTable = false;
      this.data = [];
      unitList({
        name: this.searchBrand,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      })
        .then((res: any) => {
          let result = res.data;
          this.total = result.total;
          let level = 0;
          this.convertKey(result.data, level);
          this.data = result.data;
          this.loading = false;
          this.showTable = true;
          setTimeout(() => {
            (this as any).defaultExpandedRowKeys = []//重置展开行为空
          }, 200);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    convertKey(arr, level) {
      level++;
      arr.forEach((item) => {
        item.level = level;
        if (item.valueList) {
          if (item["valueList"].length != 0) {
            item.level = level;
            item["children"] = item["valueList"];
            (this as any).convertKey(item.valueList, level);
          }
        }
      });
      return arr;
    },
    todoTeload() {
      this.getBrandList();
    },
    //搜索框搜索
    search() {
      this.loading = true;
      this.showTable = false;
      this.defaultExpandAllRows = true;
      unitList({
        name: this.searchBrand,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      })
        .then((res: any) => {
          this.showTable = true;
          let result = res.data;
          this.total = result.total;
          let level = 0;
          this.convertKey(result.data, level);
          this.data = result.data;
          this.loading = false;
          if (this.searchBrand !== "") {
            this.defaultExpandAllRows = true;
          }
          if (this.searchBrand === "") {
            this.defaultExpandAllRows = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //重置
    reset() {
      this.searchBrand = "";
      this.pageNum = 1;
      this.search();
    },
  },
  watch: {
    //监听页码和页面数量变化
    pageCurrent() {
      this.pageNum = this.pageCurrent;
      this.getBrandList();
    },
  },
  mounted() {
    // this.scroll.y = window.screen.height - 468;
    //获取数据列表信息
    this.getBrandList();
    // 获取columns 拼接操作栏
    (this as any).columns = [
      ...unitColumns,
      {
        title: "操作",
        slots: { customRender: "handle" },
        fixed: "right",
        width: 100,
      },
    ];
  },
});
