<template>
  <Modal
    v-model:visible="visible"
    centered
    @ok="handleOk"
    class="modal barndmodal"
    :confirmLoading="confirmLoading"
    :zIndex="200"
    width="800px"
    :okButtonProps="{ disabled: formState.brandName == '' }"
  >
    <template #title>
      <DecorateModalTitle :modalTitle="title" />
    </template>
    <div class="outcell">
      <Form
        :model="formState"
        :rules="rules"
        ref="formRef"
        :label-col="{ style: { width: '80px' } }"
      >
        <Form-item label="分类名称" name="brandName">
          <Input v-model:value="formState.brandName" placeholder="请输入" />
        </Form-item>
        <Form-item label="分类编码" name="code">
          <Input v-model:value="formState.code" placeholder="请输入" />
        </Form-item>

        <div style="position: relative">
          <Form-item label="排序" name="sort">
            <Input
              v-model:value="formState.sort"
              placeholder="请输入权重 若不输入默认为1"
            />
          </Form-item>
          <div
            style="
              position: absolute;
              top: 20px;
              transform: translateY(-50%);
              right: -20px;
              color: #d9d9d9;
              font-size: 14px;
            "
          >
            <Popover>
              <template #content>
                <span style="color: #8c8c8c">权重值越大，排序越靠前</span>
              </template>
              <QuestionCircleOutlined class="icon" title="" />
            </Popover>
          </div>
        </div>
        <Form-item label="分类描述" name="memo">
          <textarea
            v-model="formState.memo"
            cols="70"
            rows="5"
            maxlength="120"
            placeholder=""
            style="width: 360px"
            class="textarea"
          ></textarea>
        </Form-item>
      </Form>
    </div>
  </Modal>
</template>
<script>
import { defineComponent ,createVNode} from "vue";
import {
  Modal,
  Form,
  Input,
  Cascader,
  Upload,
  message,
  Image,
  Popover,
} from "ant-design-vue";
import {
  PlusOutlined,
  CloseCircleFilled,
  LoadingOutlined,
  EyeFilled,
  EditFilled,
  PictureOutlined,
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import { unitAdd } from "@/api/terminalList.js";
import { unitRules } from "@/rules/rules";
import DecorateModalTitle from "@/components/modification/DecorateModalTitle.vue";
const { Item } = Form;
export default defineComponent({
  components: {
    DecorateModalTitle,
    Modal,
    Form,
    FormItem: Item,
    Input,
    Cascader,
    Upload,
    PlusOutlined,
    CloseCircleFilled,
    LoadingOutlined,
    EyeFilled,
    EditFilled,
    PictureOutlined,
    Image,
    Popover,
    QuestionCircleOutlined,
  },
  props: {
    brandList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      visible: false,
      title: "新增分类",
      formState: {
        brandName: "",
        memo: null,
        sort: "",
        code: "",
      },
      rules: unitRules,
      //分类的联级选择选项
      options: [],
      cityCurrent: 0,
      notUploaded: true,
      nowDate: "",
      fileUrl: "",
      confirmLoading: false,
      firstImg: "",
      imgFlag: true,
      filelist: [],
      recode: {},
    };
  },
  methods: {
    showModal(recode) {
      this.recode = recode;
      if (recode) {
        this.title = `编辑分类`;
      }
      this.visible = true;
    },
    handleOk() {
      this.$refs.formRef.validate().then(() => {
        let that = this;
        Modal.confirm({
          title: "提示",
          icon: () => createVNode(ExclamationCircleOutlined),
          content:
            "是否确认新增该分类,新增成功后分类名称和分类编码将不可再次修改",
          cancelText: "取消",
          okText: "确认",
          onOk() {
            that.confirmLoading = true;
            unitAdd({
              name: that.formState.brandName.trim(),
              memo: that.formState.memo,
              seq: that.formState.sort||1,
              code: that.formState.code.trim(),
              key: "CODE",
            })
              .then((res) => {
                if (res.data.success) {
                  message.success("新增分类成功");
                  that.$emit("todoTeload");
                  that.$emit("myevent");
                  that.visible = false;
                  that.confirmLoading = false;
                  that.formState = {
                    brandName: "",
                    memo: null,
                    sort: "",
                    code: "",
                  };
                } else {
                  that.confirmLoading = false;
                }
              })
              .catch(() => {
                that.confirmLoading = false;
              });
          },
        });
      });
    },
  },
  mounted() {},
});
</script>
<style lang="scss" scoped>
.modal {
  border-radius: 20px;
}
input {
  width: 70%;
}
.tips {
  color: #ccc;
  text-align: center;
  position: absolute;
  bottom: 12px;
  left: 140px;
}
.textarea {
  border-radius: 5px;
  border-color: #e1e1e1;
}
.uploadBtn {
  width: 120px;
  height: 120px;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.upload-text {
  color: #bfbfbf;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
  .ant-upload-text {
    margin-top: 10px;
  }
}

.status {
  position: absolute;
  top: -50px;
  left: -50px;
}

.imgBox {
  width: 120px;
  position: relative;
  .imgIcon {
    position: absolute;
    top: -11px;
    right: -11px;
    cursor: pointer;
    color: #008bcf;
    display: none;
    font-size: 16px;
  }
  .editBox {
    font-size: 12px;
    color: #ffffff;
    font-weight: 600;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    display: none;
    .span {
      cursor: pointer;
      color: #fff;
    }
    .span:nth-last-of-type(1) {
      margin-left: 12px;
    }
  }
}
.mask {
  width: 120px;
  height: 120px;
  opacity: 0.6;
  background: #000000;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
.imgBox:hover .imgIcon {
  display: block;
}
.imgBox:hover .mask {
  display: block;
}
.imgBox:hover .editBox {
  z-index: 10;
  display: block;
}
</style>
<style lang="scss" >
.barndmodal .ant-upload:not(.notUpload .ant-upload) {
  width: 120px !important;
  height: 120px !important;
  background: #fafafa;
  border-radius: 2px;
}
.barndmodal .ant-select-selection-placeholder {
  height: 40px !important;
  line-height: 40px !important;
}
.barndmodal .ant-select-selector {
  height: 40px !important;
  line-height: 40px !important;
}
.barndmodal .ant-select-selection-item {
  height: 40px !important;
  line-height: 40px !important;
}
.barndmodal input {
  width: 360px;
  height: 40px;
  line-height: 40px;
  border-radius: 2px;
  border: 1px solid #d9d9d9;
}
</style>