import { NotImplemented } from "http-errors";
const checkbrandName = async (rule, value) => {
    if (value === undefined) {
        value = ""
    }
    var reg = new RegExp("^[A-Za-z0-9\u4e00-\u9fa5]+$");
    var value = value.trim();
    if (value.length < 1) {
        return Promise.reject('请输入名称');
    }
    if (!reg.test(value)) {
        return Promise.reject('仅支持汉字、字母、数字');
    }
}
const areaName = async (rule, value) => {
    if (value === undefined) {
        value = ""
    }
    var reg = new RegExp("^[\u4e00-\u9fa5]+$");
    var value = value.trim();
    if (!reg.test(value) && value !== '') {
        return Promise.reject('仅支持汉字');
    }
}
const areaCode = async (rule, value) => {
    if (value === undefined) {
        value = ""
    }
    var reg = new RegExp("^[A-Za-z0-9]+$");
    var value = value.trim();
    if (!reg.test(value) && value !== '') {
        return Promise.reject('仅支持字母或数字');
    }
}

const areaMemo = async (rule, value) => {
    if (value === undefined) {
        value = ""
    }
    var reg = new RegExp("^[A-Za-z0-9\u4e00-\u9fa5]+$");
    var value = value.trim();
    if (!reg.test(value) && value !== '') {
        return Promise.reject('仅支持汉字、字母或数字');
    }
}
export const AreaRules = {
    // spName: [
    //     {
    //         required: true,
    //         trigger: "blur",
    //         validator: checkspName,
    //     },
    //     { min: 0, max: 200, message: "最多200字符", trigger: "blur" },
    // ],
    code: [
        {
            trigger: "blur",
            validator: areaCode,
        },
        { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
    ],
    province: [
        {
            required: true,
            trigger: "blur",
            validator: areaName,
        },
        { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
    ],
    provinceCode: [
        {
            required: true,
            trigger: "blur",
            validator: areaCode,
        },
        { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
    ],

    city: [
        {
            trigger: "blur",
            validator: areaName,
        },
        { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
    ],
    cityCode: [
        {
            trigger: "blur",
            validator: areaCode,
        },
        { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
    ],

    area: [
        {
            trigger: "blur",
            validator: areaName,
        },
        { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
    ],
    areaCode: [
        {
            trigger: "blur",
            validator: areaCode,
        },
        { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
    ],

    town: [
        {
            trigger: "blur",
            validator: areaName,
        },
        { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
    ],
    townCode: [
        {
            trigger: "blur",
            validator: areaCode,
        },
        { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
    ],
    memo: [
        {
            trigger: "blur",
            validator: areaMemo,
        },
        { min: 0, max: 255, message: "最多255字符", trigger: "blur" },
    ],
}


//终端详情验证规则
//terminalDetailsRules 终端详情页面验证规则相关
const checkTerminalName = async (rule, value) => {
    var reg = new RegExp("^[A-Za-z0-9\u4e00-\u9fa5]+$");
    var value = value.trim();
    if (value.length < 1) {
        return Promise.reject('请输入终端名称');
    }
    if (!reg.test(value)) {
        return Promise.reject('仅支持汉字、字母或数字 例如:沃尔玛大连金马路店');
    }
}
const checkTerminalCode = async (rule, value) => {
    var reg = new RegExp("^[A-Za-z0-9]+$");
    var value = value.trim();

    if (!reg.test(value) && value.length != 0) {
        return Promise.reject('仅支持字母或数字');
    }
}

const checkProvince = async (rule, value) => {
    if (value.length < 1) {
        return Promise.reject('请选择省市区');
    }
    if (value.length < 2) {
        return Promise.reject('请完整选择对应城市及区县');
    }
    if (value.length < 3) {
        return Promise.reject('请完整选择对应的区县');
    }
}
const checkTerminalCategory = async (rule, value) => {

    if (value[0] == '' || value[0] == undefined || value[0] == null) {
        return Promise.reject('请选择渠道');
    }
    if (value[1] == '' || value[1] == undefined || value[1] == null) {
        return Promise.reject('请完整选择渠道');
    }
    if (value[2] == '' || value[2] == undefined || value[2] == null) {
        return Promise.reject('请完整选择渠道');
    }

}
const validatorBaidu = async (rule, value) => {
    if (value.length === 0) {
        return Promise.reject('请输入');
    }
    let array = value.split(',')
    let flag = false
    array.forEach((el) => {
        if (String(Number(el.trim())) === 'NaN' || el === '') {
            flag = true
        }
    })
    if (array.length != 2 || flag) {
        return Promise.reject('请按照 经度,纬度 的方式输入  例如:121.241908,30.835494');
    }
}


export const terminalDetailsRules = {
    terminalName: [
        {
            required: true,
            trigger: "blur",
            message: "请输入终端名称",
            // validator: checkTerminalName,
        },
        { min: 0, max: 200, message: "最多200字符", trigger: "blur" },
    ],

    province: [
        {
            required: true,
            validator: checkProvince,
            trigger: "blur",
        },
    ],
    town: [
        {
            trigger: "blur",
        },
        { max: 50, message: "最多50个字符", trigger: "blur" },
    ],
    address: [
        {
            required: true,
            message: "请输入终端地址",
            trigger: "blur",
        },
        { min: 0, max: 200, message: "最多200个字符", trigger: "blur" },
    ],
    terminalCategory: [
        {
            required: true,
            validator: checkTerminalCategory,
            trigger: "blur",

        },
    ],
    // 又改成非必填的了
    // childChannel: [
    //     {
    //         required: true,
    //         trigger: "blur",
    //         message: "请选择子渠道",
    //     },
    // ],
    licenseCode: [
        {
            trigger: "blur",
        },
        { min: 0, max: 32, message: "最多32字符", trigger: "blur" },
    ],
    licenseName: [
        {
            trigger: "blur",
        },
        { min: 0, max: 32, message: "最多32字符", trigger: "blur" },
    ],
    licenseAddress: [
        {
            trigger: "blur",
        },
        { min: 0, max: 100, message: "最多100字符", trigger: "blur" },
    ],
    licenseDay: [
        {
            trigger: "blur",
        },
        { min: 0, max: 32, message: "最多32字符", trigger: "blur" },
    ],
    licenseType: [
        {
            trigger: "blur",
        },
        { min: 0, max: 32, message: "最多32字符", trigger: "blur" },
    ],
    licenseExpiry: [
        {
            trigger: "blur",
        },
        { min: 0, max: 32, message: "最多32字符", trigger: "blur" },
    ],
    baidu: [
        {
            required: true,
            validator: validatorBaidu,
            trigger: "blur",
        },
    ],
};


//品牌 的rules
const checkSort = async (rule, value) => {
    var reg = new RegExp("^[0-9]+$");
    var value = value.trim();
    if (value.length === 0) {
        return
    }
    if (!reg.test(value)) {
        return Promise.reject('仅支持输入数字');
    }
}
export const brandRules = {
    brandName: [
        {
            required: true,
            validator: checkbrandName,
            trigger: "blur",
        },
        { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
    ],
    sort: [
        {
            trigger: "blur",
            validator: checkSort,
        },
        { min: 0, max: 4, message: "最多4位数字", trigger: "blur" },
    ],
}


//单品的校验规则
//单品详情页面的rules 及验证规则
const checkspName = async (rule, value) => {
    if (value === undefined || value === null) {
        value = ""
    }
    var reg = new RegExp("^[*A-Za-z0-9\u4e00-\u9fa5]+$");
    var value = value.trim();
    if (value.length < 1) {
        return Promise.reject('请输入名称');
    }
    if (!reg.test(value)) {
        return Promise.reject('仅支持汉字、字母、数字或 *');
    }
}
const checkspCode = async (rule, value) => {

    if (value === undefined || value === null) {
        value = ""
    }
    var reg = new RegExp("^[A-Za-z0-9]+$");
    var value = value.trim();
    if (!reg.test(value) && value !== '') {
        return Promise.reject('仅支持字母或数字');
    }
}
const checkspBarCode = async (rule, value) => {
    if (value === undefined || value === null) {
        value = ""
    }
    var reg = new RegExp("^69[0-9]{11,11}$");
    var value = value.trim();
    if (value.length < 1) {
        return Promise.reject('请输入条形码');
    }
    const x = value.slice(-1)
    const list = [...value]
    const Odd = [] //奇数位
    const even = []//偶数位
    for (let i = 0; i < list.length - 1; i++) {
        if (i % 2 == 0) {
            Odd.push(list[i])
        } else {
            even.push(list[i])
        }
    }
    const even3 = even.map(el => el * 3)
    let evenSum = 0
    let OddSum = 0
    Odd.forEach(el => {
        OddSum = Number(OddSum) + Number(el)
    })
    even3.forEach(el => {
        evenSum = Number(evenSum) + Number(el)
    })
    const y = Math.ceil((evenSum + OddSum) / 10) * 10 - (evenSum + OddSum)
    console.log(x, y);
    if (!reg.test(value) || x != y) {
        return Promise.reject('条形码不符合规范');
    }
}
const spNickName = async (rule, value) => {
    if (value === undefined || value === null) {
        value = ""
    }
    var reg = new RegExp("^[A-Za-z0-9\u4e00-\u9fa5]+$");
    var value = value.trim();
    if (!reg.test(value) && value !== '') {
        return Promise.reject('仅支持汉字、字母或数字');
    }
}
const salesPrice = async (rule, value) => {

    if (value === undefined || value === null) {
        value = ""
    }
    console.log(typeof (value));
    var reg = new RegExp("^[0-9]+$");
    if (typeof (value) != 'number') {
        var value = value.trim();
    }
    if (!reg.test(value) && value !== '' && typeof (value) != 'number') {
        return Promise.reject('仅可输入数字');
    }
    if (value.length > 5) {
        return Promise.reject('最多只支持5位数字');
    }
}
const salesPriceA = async (rule, value) => {

    if (value === undefined || value === null) {
        value = ""
    }
    var reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/
    if (typeof (value) != 'number') {
        var value = value.trim();
    }
    if (!reg.test(value) && value !== '') {
        return Promise.reject('仅可输入数字,最多两位小数');
    }
    if (value.length > 9) {
        return Promise.reject('最多只支持9位数字');
    }
}
const list = async (rule, value) => {
    if (value === undefined || value === null) {
        value = []
    }
    if (value[0] === '未分类') {
        return Promise.reject('请输选择分类');
    }
    if (value.length == 0) {
        return Promise.reject('请输选择');
    }
}

export const SingleProductRules = {
    spName: [
        {
            required: true,
            trigger: "blur",
            validator: checkspName,
        },
        { min: 0, max: 200, message: "最多200字符", trigger: "blur" },
    ],
    spCode: [
        {
            trigger: "blur",
            validator: checkspCode,
        },
        { min: 0, max: 32, message: "最多32字符", trigger: "blur" },
    ],
    spBarCode: [
        {
            required: true,
            trigger: "blur",
            validator: checkspBarCode,
        },
        // { min: 0, max: 13, message: "最多13字符", trigger: "blur" },
    ],
    spNickName: [
        {
            trigger: "blur",
            validator: spNickName,
        },
        { min: 0, max: 20, message: "最多20字符", trigger: "blur" },
    ],
    stockUnitCode: [
        {
            required: true,
            trigger: "blur",
            message: "请选择单位",
        },
    ],
    salesPrice: [
        {
            trigger: "blur",
            validator: salesPriceA,
        },
        // { min: 0, max: 9, message: "请输入0-999999999之间的数字", trigger: "blur" },
    ],
    shelfLife: [
        {
            trigger: "blur",
            validator: salesPrice,
        },
        // { min: 0, max: 5, message: "请输入0-99999之间的数字", trigger: "blur" },
    ],
    sizeHigh: [
        {
            trigger: "blur",
            validator: salesPrice,
        },
        // { min: 0, max: 5, message: "请输入0-99999之间的数字", trigger: "blur" },
    ],
    sizeLong: [
        {
            trigger: "blur",
            validator: salesPrice,
        },
        // { min: 0, max: 5, message: "请输入0-99999之间的数字", trigger: "blur" },
    ],
    sizeWide: [
        {
            trigger: "blur",
            validator: salesPrice,
        },
        // { min: 0, max: 5, message: "请输入0-99999之间的数字", trigger: "blur" },
    ],
    brandNameList: [
        {
            // required: true,
            // trigger: "blur",
            message: "请选择品牌",
            validator: list,
        },
    ],
    categoryNameList: [
        {
            // required: true,
            // trigger: "blur",
            message: "请选择品类",
            validator: list,
        },
    ],
}




//基础数据相关校验
const unitname = async (rule, value) => {
    if (value === undefined) {
        value = ""
    }
    var reg = new RegExp("^[A-Za-z0-9\u4e00-\u9fa5]+$");
    var value = value.trim();
    if (!reg.test(value) && value !== '') {
        return Promise.reject('仅支持汉字、字母或数字');
    }
    if (value.length === 0) {
        return Promise.reject('请输入名称');
    }
}
export const unitRules = {
    brandName: [
        {
            required: true,
            trigger: "blur",
            validator: unitname,
        },
        { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
    ],
    sort: [
        {
            trigger: "blur",
            validator: checkSort,
        },
        { min: 0, max: 4, message: "最多4位数字", trigger: "blur" },
    ],
    code: [
        {
            required: true,
            message: "请输入编码",
            trigger: "blur",
        },
        { min: 0, max: 50, message: "最多50字符", trigger: "blur" },
    ],
}